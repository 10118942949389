<template>
  <div v-loading="loading" :key="once">
    <div>
      <template v-if="businessInformation.principalUserName">
        <el-descriptions
          style="margin-top: 10px; margin-bottom: 10px"
          title="商务基础信息"
          direction="vertical"
          :column="5"
          border>
          <el-descriptions-item label="商务名称" :span="2">
            {{ businessInformation.businessName }}
          </el-descriptions-item>
          <el-descriptions-item label="项目来源" :span="1">
            {{ businessInformation.projectSource | dict(dictData.projectSource) }}
          </el-descriptions-item>
          <el-descriptions-item label="商务类型" :span="1">
            {{ businessInformation.businessType | dict(dictData.businessType) }}
          </el-descriptions-item>
          <el-descriptions-item label="业务种类" :span="1">
            {{ businessInformation.businessLines | dict(dictData.businessLines) }}
          </el-descriptions-item>

          <el-descriptions-item label="业主单位" :span="3">
            {{ businessInformation.ownerUnit }}
          </el-descriptions-item>
          <el-descriptions-item label="项目承接公司" :span="1">
            {{ businessInformation.companyType | dict(dictData.companyType) }}
          </el-descriptions-item>
          <el-descriptions-item label="实际商务负责人" :span="1">
            {{ businessInformation.principalUserName | addDept(CompanyMembers) }}
          </el-descriptions-item>
        </el-descriptions>
      </template>

      <el-table
        ref="multipleTable"
        border
        :data="table"
        :default-expand-all="true"
        tooltip-effect="dark"
        max-height="600"
        height="string"
        v-loading="loading">
        <el-table-column
          align="center"
          prop="content"
          label="工作内容"
          width="230"></el-table-column>
        <el-table-column
          align="center"
          prop="participatingDistributor"
          label="参与角色"
          width="250"></el-table-column>
        <el-table-column align="center" label="参与分配方" :show-overflow-tooltip="false">
          <template slot-scope="scope">
            <el-table
              v-if="
                scope.row.ruleCode == 'PROJECT_IMPLEMENTATION' ||
                scope.row.ruleCode == 'CONSULT_BIDDING_SCHEME'
              "
              ref="multipleTable"
              :key="once1"
              border
              height="string"
              max-height="200"
              v-show="scope.row.fp.length > 0"
              :data="scope.row.fp"
              tooltip-effect="dark">
              <el-table-column
                align="center"
                type="index"
                label="序号"
                :show-overflow-tooltip="false"></el-table-column>
              <el-table-column align="center" prop="deptId" label="部门名称">
                <template slot-scope="scopes">
                  {{ scopes.row.deptId | cascader(deptData, 'id', 'deptName') }}
                </template>
              </el-table-column>
            </el-table>

            <el-table
              v-else
              ref="multipleTable"
              :key="once"
              border
              height="string"
              max-height="200"
              v-show="scope.row.fp.length > 0"
              :data="scope.row.fp"
              tooltip-effect="dark">
              <el-table-column
                align="center"
                width="100"
                type="index"
                label="序号"></el-table-column>
              <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
              <!-- <el-table-column
                align="center"
                label="商务经理"
                v-if="scope.row.ruleCode == 'BUSINESS_FOLLOW_UP'"
              >
                <template slot-scope="scopes">
                  <el-checkbox
                    :value="scopes.row.businessManagerSign == 'HAVE' ? true : false"
                    disabled
                  ></el-checkbox>
                </template>
              </el-table-column> -->
              <el-table-column align="center" prop="deptNames" label="部门"></el-table-column>
              <el-table-column align="center" prop="postName" label="岗位"></el-table-column>
              <el-table-column align="center" prop="phone" label="联系电话"></el-table-column>
            </el-table>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {},

  props: {
    ManageId: {},
  },
  data() {
    return {
      loading: false,
      form: {},
      type: 'portrait',
      tableData: [],
      tableData2: [],
      deptData: [],
      table: [],
      // userList:[],
      content1: [
        {
          content: '负责关键沟通与组织',
          participatingDistributor: '商务负责人',
          ruleCode: 'LEAD_COMMUNICATION',
          fp: [],
        },
        {
          content: '提供有效项目信息',
          participatingDistributor: '信息提供人',
          ruleCode: 'PROVIDE_INFORMATION',
          fp: [],
        },
        {
          content: '商务跟进',
          participatingDistributor: '商务助理',
          ruleCode: 'BUSINESS_FOLLOW_UP',
          fp: [],
        },
        {
          content: '有效引荐',
          participatingDistributor: '引荐人',
          ruleCode: 'REFERRER',
          fp: [],
        },
        {
          content: '售前和投标技术方案编制',
          participatingDistributor: '售前和投标技术方案编制部门',
          ruleCode: 'CONSULT_BIDDING_SCHEME',
          fp: [],
        },
        { content: '其他', participatingDistributor: '公司', ruleCode: 'OTHER', fp: [] },
      ],
      content2: [
        {
          content: '负责关键沟通与组织',
          participatingDistributor: '商务负责人',
          ruleCode: 'LEAD_COMMUNICATION',
          fp: [],
        },
        {
          content: '提供有效项目信息',
          participatingDistributor: '信息提供人',
          ruleCode: 'PROVIDE_INFORMATION',
          fp: [],
        },
        {
          content: '商务跟进',
          participatingDistributor: '商务助理',
          ruleCode: 'BUSINESS_FOLLOW_UP',
          fp: [],
        },
        { content: '有效引荐', participatingDistributor: '引荐人', ruleCode: 'REFERRER', fp: [] },
        {
          content: '售前和投标技术方案编制',
          participatingDistributor: '售前和投标技术方案编制部门',
          ruleCode: 'CONSULT_BIDDING_SCHEME',
          fp: [],
        },
        { content: '其他', participatingDistributor: '公司', ruleCode: 'OTHER', fp: [] },
      ],
      content3: [
        {
          content: '负责关键沟通与组织',
          participatingDistributor: '商务负责人',
          ruleCode: 'LEAD_COMMUNICATION',
          fp: [],
        },
        {
          content: '提供有效项目信息',
          participatingDistributor: '信息提供人',
          ruleCode: 'PROVIDE_INFORMATION',
          fp: [],
        },
        {
          content: '商务跟进',
          participatingDistributor: '商务助理',
          ruleCode: 'BUSINESS_FOLLOW_UP',
          fp: [],
        },
        {
          content: '有效引荐（历史商务关系)',
          participatingDistributor: '引荐人',
          ruleCode: 'HISTORICAL_BUSINESS_REFERRER',
          fp: [],
        },
        {
          content: '售前和投标技术方案编制',
          participatingDistributor: '售前和投标技术方案编制部门',
          ruleCode: 'CONSULT_BIDDING_SCHEME',
          fp: [],
        },
        {
          content: '驻场或原项目实施',
          participatingDistributor: '驻场团队或原实施团队所在部门',
          ruleCode: 'PROJECT_IMPLEMENTATION',
          fp: [],
        },
        { content: '其他', participatingDistributor: '公司', ruleCode: 'OTHER', fp: [] },
      ],
      once: 0, //element的bug，需要更新div的key 表单高度才能正常显示
      once1: 0,
      dictData: {
        businessType: [],
        businessDisbursenentType: [],
        projectSource: [],
        companyType: [],
        businessLines: [],
      },
    }
  },
  inject: ['re'],
  computed: {
    ...mapState({
      businessInformation: state => state.business.businessInformation,
      userAssignmentMap: state => state.business.userAssignmentMap,
      CompanyMembers: state => state.business.CompanyMembers,
    }),
  },
  mounted() {},
  watch: {
    // ManageId: {
    //   immediate: true, //初始化立即执行
    //   handler: function(newVal, oldVal) {
    //     if (newVal && newVal != undefined) {
    //       // this.getUserList();
    //       this.getManage();
    //     }
    //   },
    // },
    businessInformation: {
      deep: true,
      immediate: true,
      handler: function (val, old) {
        if (val.businessType == 'WSQGTJBLXM') {
          this.table = this.content1.deepClone()
        } else if (val.businessType == 'YSQGTXKHXM') {
          this.table = this.content2.deepClone()
        } else {
          //YSQGTLKHXM
          this.table = this.content3.deepClone()
        }
        if (this.userAssignmentMap.userAssignment) {
          this.userAssignmentMap.userAssignment.forEach(e => {
            this.table.forEach(i => {
              if (i.ruleCode == e.ruleCode) {
                i.fp.push(e)
              }
            })
          })
          this.once += 1
        }
      },
    },
  },
  created() {
    this.getUserList()
    this.$api.dict
      .listSysDictData('COMPANY_TYPE', true)
      .then(res => {
        this.dictData.companyType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_TYPE', true)
      .then(res => {
        this.dictData.businessType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('PROJECT_SOURCE', true)
      .then(res => {
        this.dictData.projectSource = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_LINES', true)
      .then(res => {
        this.dictData.businessLines = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    // 添加部门前缀
    addDept(val, n) {
      let name = ''
      if (n && n.length > 0) {
        for (let index = 0; index < n.length; index++) {
          const v = n[index]
          if (v.userName == val) {
            var newArr = v.departmentName.split(',')
            name = v.userName + '-' + newArr[0]
            break
          }
        }
      } else {
        name = val
      }
      return name
    },
  },
  methods: {
    getUserList() {
      this.$api.sysDept.listDept({}).then(res => {
        this.deptData = res.data
      })
    },
    //获取商务类型
    getManage() {
      this.loading = true
      if (this.ManageId == undefined) {
        this.loading = false
        return
      }
      this.$api.businessManage
        .getBusinessManage(this.ManageId)
        .then(res => {
          this.loading = false
          this.Dispose_data = res.data.businessManage
          this.getData()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    getData() {
      this.loading = true
      this.$api.businessManage
        .getUserAssignment(this.ManageId)
        .then(res => {
          this.loading = false

          if (res.data.principalUser == null) {
            this.tableData = []
          } else {
            this.tableData = res.data.principalUser
          }
          if (res.data.userAssignment == null) {
            this.tableData2 = []
          } else {
            // this.tableData2 = res.data.userAssignment;
            if (this.Dispose_data.businessType == 'WSQGTJBLXM') {
              this.table = this.content1.deepClone()
            } else if (this.Dispose_data.businessType == 'YSQGTXKHXM')
              this.table = this.content2.deepClone()
            else {
              //YSQGTLKHXM
              this.table = this.content3.deepClone()
            }

            let arr = res.data.userAssignment
            let new_arr = []
            let index = 0
            arr.forEach(e => {
              this.table.forEach(i => {
                if (i.ruleCode == e.ruleCode) {
                  e.index = index += 1
                  i.fp.push(e)
                }
              })

              new_arr.push(e)
            })
            this.tableData2 = new_arr
            this.once += 1
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // getUserList(){
    //     this.$api.user.listUserInfo().then(res => {
    //       this.userList = res.data;
    //     }).catch(err => {
    //         console.log(err);
    //     });
    // },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/edit.scss';
@import '@/styles/config.scss';

// .p_t_35{
//   padding-top:35px;
// }
// .p_b{
//   padding-bottom: 25px;
// }
// .el-table {
//     height:calc(100vh - 347px);
// }

@import '@/styles/config.scss';

.annex {
  display: flex;
  justify-content: space-between;
  /deep/.el-card {
    width: 50%;
    margin: 0 10px;
    .el-card__header {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }
    // .el-table {
    //     height:calc(100vh - 347px);
    // }
  }
}

.el-table__body-wrapper /deep/ is-scrolling-none {
  height: 100% !important;
}

/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff !important;
  border-color: #409eff !important;
  &::after {
    border-color: white !important;
  }
}
</style>
